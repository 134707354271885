import { SelectOption } from '@company/ui/types/select';
import { MentionOptions } from '@tiptap/extension-mention';
import { PluginKey } from '@tiptap/pm/state';
import { ReactRenderer } from '@tiptap/react';
import tippy, { Instance } from 'tippy.js';
import { SuggestionList, SuggestionListRef } from './suggestion-menu';
import { SuggestionItemsQuery } from './types';

export const suggestion = <T extends SelectOption>({
  char,
  items,
  emptyState
}: {
  char: string;
  items: SuggestionItemsQuery<T>;
  emptyState: React.ReactNode;
}): MentionOptions<T>['suggestion'] => {
  return {
    char,
    pluginKey: new PluginKey(`suggestion-${char}`),
    allowSpaces: true,
    items,
    render: () => {
      let component: ReactRenderer<SuggestionListRef>;
      let popup: Instance;

      return {
        onStart: props => {
          component = new ReactRenderer(SuggestionList, {
            props: {
              ...props,
              emptyState
            },
            editor: props.editor
          });

          if (!props.clientRect) {
            return;
          }

          popup = tippy(document.body, {
            getReferenceClientRect: () => props.clientRect!()!,
            appendTo: () => document.body,
            content: component.element,
            showOnCreate: true,
            interactive: true,
            trigger: 'manual',
            placement: 'bottom-start'
          });
        },
        onUpdate(props) {
          component.updateProps(props);

          if (!props.clientRect) {
            return;
          }

          popup.setProps({
            getReferenceClientRect: () => props.clientRect!()!
          });
        },
        onKeyDown(props) {
          if (props.event.key === 'Escape') {
            popup.hide();
            return true;
          } else if (props.event.key === 'Enter') {
            props.event.preventDefault();
            props.event.stopPropagation();
            return component.ref?.onKeyDown(props) || true;
          }

          return component.ref?.onKeyDown(props) || false;
        },
        onExit() {
          popup.destroy();
          component.destroy();
        }
      };
    }
  };
};
