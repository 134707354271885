import { FieldLinkedRowConfig, FieldNumberConfig, FieldSelectConfig, FieldType } from './field';
import { TableRowDto, tableRowFilterSchema, UpsertTableRow } from './row';
import { ViewState, ViewType } from './view';
import { z } from 'zod';

export const tableTypeSchema = z.enum(['TABLE', 'CHILD_TABLE']);
export type TableType = z.infer<typeof tableTypeSchema>;

export interface CreateTable {
  id?: string;
  name: string;
  projectId: string | null;
  fields: CreateTableField[];
  rows: UpsertTableRow[];
  views: CreateTableView[];
  childTable: CreateTable | null;
}

export interface CreateTableField {
  id?: string;
  referenceId: string;
  name: string;
  type: FieldType;
  isEditable: boolean;
  isPrimary: boolean;
  isRagSearchable: boolean;
  linkedRowConfig: FieldLinkedRowConfig | null;
  selectConfig: FieldSelectConfig | null;
  numberConfig: FieldNumberConfig | null;
}

export interface CreateTableView<TType extends ViewType = 'GRID'> {
  name: string;
  type: TType;
  state: ViewState<TType>;
}

export interface ExportTable {
  id: string;
  name: string;
  fields: ExportTableField[];
  rows: TableRowDto[];
  view: ExportTableView;
  childTable: ExportTable | null;
}

export interface ExportTableField {
  id: string;
  referenceId: string;
  name: string;
  type: FieldType;
}

export interface ExportTableView<TType extends ViewType = 'GRID'> {
  name: string;
  type: TType;
  state: ViewState<TType>;
}

export const findTableSchema = z.object({
  tableId: z.string(),
  where: z
    .object({
      row: tableRowFilterSchema
    })
    .optional()
});
export type FindTableArgs = z.infer<typeof findTableSchema>;

export const cloneTableSchema = findTableSchema.extend({
  newTableName: z.string().optional(),
  projectId: z.string().nullable()
});
export type CloneTableArgs = z.infer<typeof cloneTableSchema>;
