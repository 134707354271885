'use client';

import { Icon, IconButton, type ButtonProps } from '@company/ui/components';
import { ArrowDownIcon } from '@company/ui/icons';

interface ButtonScrollToBottomProps extends ButtonProps {
  isAtBottom: boolean;
  scrollToBottom: () => void;
}

export function ButtonScrollToBottom({
  isAtBottom,
  scrollToBottom,
  ...props
}: ButtonScrollToBottomProps) {
  return (
    <IconButton
      variant="outline"
      rounded={'full'}
      transition="opacity 0.3s"
      opacity={isAtBottom ? 0 : 1}
      bgColor="white"
      onClick={() => scrollToBottom()}
      {...props}
    >
      <Icon as={ArrowDownIcon} fontSize={'lg'} />
    </IconButton>
  );
}
