'use client';

import {
  Icon,
  IconButton,
  Input,
  Group,
  InputProps,
  InputElement,
  Anchor
} from '@company/ui/components';
import { FieldValues } from '@company/ui/hooks/form';
import { EyeIcon, EyeOffIcon } from '@company/ui/icons';
import { useState } from 'react';
import { FormInput, FormInputPropsWithoutChildren } from '.';
import { Box, Flex, Field } from '@chakra-ui/react';

export const TextInput = <TFieldValues extends FieldValues>({
  form,
  name,
  root,
  label,
  labelProps,
  isDisabled,
  placeholder,
  helperText,
  helperProps,
  errorProps,
  hideError,
  isRequired,
  autoFocus,
  size = 'md'
}: FormInputPropsWithoutChildren<TFieldValues> & {
  placeholder: InputProps['placeholder'];
}) => {
  return (
    <FormInput
      form={form}
      name={name}
      root={root}
      label={label}
      size={size}
      errorProps={errorProps}
      hideError={hideError}
      helperProps={helperProps}
      helperText={helperText}
      labelProps={labelProps}
      isRequired={isRequired}
      isDisabled={isDisabled}
    >
      <Input placeholder={placeholder} size={size} autoFocus={autoFocus} {...form.register(name)} />
    </FormInput>
  );
};

export const PasswordInput = <TFieldValues extends FieldValues>({
  form,
  name,
  root,
  label,
  labelProps,
  isDisabled,
  placeholder = 'Enter password',
  helperText,
  helperProps,
  errorProps,
  hideError,
  size = 'md'
}: FormInputPropsWithoutChildren<TFieldValues> & {
  placeholder: InputProps['placeholder'];
  isDisabled: InputProps['disabled'];
}) => {
  const [show, setShow] = useState(false);
  const handleClick = () => setShow(!show);

  return (
    <FormInput
      form={form}
      name={name}
      root={root}
      label={undefined}
      size={size}
      errorProps={errorProps}
      hideError={hideError}
      helperProps={helperProps}
      helperText={helperText}
      labelProps={labelProps}
      isDisabled={isDisabled}
    >
      <>
        <Field.Label w={'full'}>
          <Flex justify={'space-between'} w={'100%'}>
            <Box>Password</Box>
            <Box>
              <Anchor href="/forgot-password" fontSize={'xs'}>
                Forgot password?
              </Anchor>
            </Box>
          </Flex>
        </Field.Label>
        <Group w={'full'}>
          <Input
            pr="3.5rem"
            type={show ? 'text' : 'password'}
            size={size}
            placeholder={placeholder}
            {...form.register(name)}
          />
          <InputElement width="3.5rem" placement={'end'}>
            <IconButton size="xs" variant="ghost" onClick={handleClick} aria-label="Show password">
              <Icon as={show ? EyeOffIcon : EyeIcon} fontSize={12} />
            </IconButton>
          </InputElement>
        </Group>
      </>
    </FormInput>
  );
};
