import { z } from 'zod';

export const singleFileUploadConfigSchema = z.object({
  type: z.literal('SINGLE'),
  minFiles: z.literal(1),
  maxFiles: z.literal(1),
  maxFileSize: z.number(),
  acceptedFileTypes: z.record(z.string().array())
});

export const multipleFileUploadConfigSchema = z.object({
  type: z.literal('MULTIPLE'),
  minFiles: z.number().optional(),
  maxFiles: z.number(),
  maxFileSize: z.number(),
  acceptedFileTypes: z.record(z.string().array())
});

export const fileUploadConfigSchema = z.discriminatedUnion('type', [
  singleFileUploadConfigSchema,
  multipleFileUploadConfigSchema
]);
export const uploadedFileSchema = z.object(
  {
    id: z.string(),
    pieceOfInformationId: z.string(),
    name: z.string(),
    extension: z.string()
  },
  {
    errorMap: () => {
      return { message: `Please provide a file` };
    }
  }
);
export type UploadedFile = z.infer<typeof uploadedFileSchema>;
export interface UploadingFile {
  id: string;
  name: string;
  extension: string;
  size: number;
  status: 'UPLOADING' | 'UPLOADED' | 'ERROR';
  file: File;
  uploadedOn: Date;
  pieceOfInformationId: string | null;
}
export type ToUploadFile = {
  content: string;
  name: string;
  extension: string;
};

export type SingleFileUploadConfig = z.infer<typeof singleFileUploadConfigSchema>;
export type MultipleFileUploadConfig = z.infer<typeof multipleFileUploadConfigSchema>;
export type FileUploadConfig = z.infer<typeof fileUploadConfigSchema>;
