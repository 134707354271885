export type LifecycleStatus = 'ACTIVE' | 'INACTIVE' | 'ARCHIVED' | 'DELETED';
export const LIFECYCLE_STATUS: Record<
  LifecycleStatus,
  {
    id: LifecycleStatus;
    label: string;
  }
> = {
  ACTIVE: {
    id: 'ACTIVE',
    label: 'Active'
  },
  INACTIVE: {
    id: 'INACTIVE',
    label: 'Inactive'
  },
  ARCHIVED: {
    id: 'ARCHIVED',
    label: 'Archived'
  },
  DELETED: {
    id: 'DELETED',
    label: 'Deleted'
  }
} as const;
