import { AgentMemoryDataType, AgentMemoryValue } from '../../types';
import { convertToPieceOfInformationDto, PieceOfInformationForDto } from '../piece-of-information';

export const getAgentMemoryValue = (agentMemory: {
  pieceOfInformation: PieceOfInformationForDto | null;
  stringValue: string | null;
}) => {
  return agentMemory?.pieceOfInformation
    ? convertToPieceOfInformationDto(agentMemory.pieceOfInformation)
    : agentMemory.stringValue!;
};

type AgentMemory = {
  dataType: AgentMemoryDataType;
  value: AgentMemoryValue;
};

export const AGENT_MEMORY_DATA_TYPE_TO_GET_VALUE_FUNCTION = {
  PIECE_OF_INFORMATION: (agentMemory: AgentMemory) =>
    convertToPieceOfInformationDto(agentMemory.value as unknown as PieceOfInformationForDto),
  STRING: (agentMemory: AgentMemory) => agentMemory.value as string
};
