// This calculates the average of two sort keys. This is useful for
// calculating the sort key of a new item that is being inserted between
// two existing items

// Javascript localeCompare is not considering uppercase by default. To make things easier we just use lowercase
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/localeCompare
const SORT_KEY_ALPHABET = '0123456789abcdefghijklmnopqrstuvwxyz';
const alphabetLength = SORT_KEY_ALPHABET.length;
const sortKeyLength = 16;

export const generateMultipleSortKeys = (
  leftSortKey: string | undefined,
  rightSortKey: string | undefined,
  count: number
) => {
  const sortKeys: string[] = [];
  let previousLeftSortKey = leftSortKey;
  for (let i = 0; i < count; i++) {
    const sortKey = generateSortKey(previousLeftSortKey, rightSortKey);
    previousLeftSortKey = sortKey;
    sortKeys.push(sortKey);
  }
  return sortKeys;
};

export const generateSortKey = (
  leftSortKey: string | undefined,
  rightSortKey: string | undefined
) => {
  leftSortKey = leftSortKey || '0000000000000000';
  rightSortKey = rightSortKey || 'zzzzzzzzzzzzzzzz';

  return getAverage(leftSortKey, rightSortKey);
};

export const generateNSortKeys = (count: number) => {
  const sortKeys: string[] = [];
  const start = debase('0000000000000000');
  const end = debase('zzzzzzzzzzzzzzzz');
  const step = Math.floor((end - start) / (count + 1));

  for (let i = 1; i <= count; i++) {
    sortKeys.push(enbase(start + i * step));
  }

  return sortKeys;
};

export const calcualteNewSortKeyInArray = <T extends { sortKey: string }>({
  arr,
  newIndex,
  oldIndex
}: {
  arr: T[];
  oldIndex: number | undefined; // New item
  newIndex: number | undefined;
}) => {
  if (newIndex === undefined) {
    return generateSortKey(arr[arr.length - 1]!.sortKey, undefined);
  }
  const movedItem = oldIndex ? arr[oldIndex]! : ({ sortKey: 'placeholder' } as T);
  const updatedArr = [...arr];

  if (oldIndex !== undefined) {
    updatedArr.splice(oldIndex, 1);
  }

  updatedArr.splice(newIndex, 0, movedItem);

  return generateSortKey(
    newIndex === 0 ? undefined : updatedArr[newIndex - 1]!.sortKey,
    newIndex + 1 < updatedArr.length ? updatedArr[newIndex + 1]!.sortKey : undefined
  );
};

export const sortBySortKey = <T extends { sortKey: string }>(arr: T[]): T[] => {
  return [...arr].sort((a, b) => a.sortKey.localeCompare(b.sortKey));
};

const getAverage = (a: string, b: string) => {
  return enbase(Math.floor((debase(a) + debase(b)) / 2));
};

const enbase = (n: number) => {
  let result = '';
  while (n > 0) {
    result += SORT_KEY_ALPHABET[n % alphabetLength];
    n = Math.floor(n / alphabetLength);
  }

  result = result.split('').reverse().join('');

  while (result.length < sortKeyLength) {
    result = `${SORT_KEY_ALPHABET[0]}${result}`;
  }
  return result;
};

const debase = (s: string) => {
  let result = 0;
  for (let i = 0; i < s.length; i++) {
    result = result * alphabetLength + SORT_KEY_ALPHABET.indexOf(s[i]!);
  }
  return result;
};
