'use client';

import { FieldValues } from '@company/ui/hooks/form';
import { SelectOption } from '@company/ui/types/select';
import { Controller } from 'react-hook-form';
import { FormInput, FormInputPropsWithoutChildren } from '.';
import {} from '..';
import { NativeSelect } from '@chakra-ui/react';

export const NativeSelectInput = <TFieldValues extends FieldValues>({
  form,
  name,
  root,
  label,
  labelProps,
  isDisabled,
  placeholder,
  helperText,
  helperProps,
  errorProps,
  hideError,
  size = 'md',
  options,
  isRequired,
  canUnselectValue = false
}: FormInputPropsWithoutChildren<TFieldValues> & {
  placeholder?: string;
  options: SelectOption<unknown>[];
  canUnselectValue?: boolean;
}) => {
  return (
    <FormInput
      form={form}
      name={name}
      root={root}
      label={label}
      size={size}
      errorProps={errorProps}
      hideError={hideError}
      helperProps={helperProps}
      helperText={helperText}
      labelProps={labelProps}
      isRequired={isRequired}
      isDisabled={isDisabled}
    >
      <Controller
        control={form.control}
        name={name}
        render={({ field }) => (
          <NativeSelect.Root size={size} w={'full'}>
            <NativeSelect.Field {...field} value={field.value ?? ''}>
              {placeholder && (
                <option value="" disabled={!canUnselectValue}>
                  {placeholder}
                </option>
              )}
              {options.map(option => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </NativeSelect.Field>
          </NativeSelect.Root>
        )}
      />
    </FormInput>
  );
};
