import {
  Spinner as ChakraSpinner,
  SpinnerProps as ChakraSpinnerProps,
  Flex,
  Text
} from '@chakra-ui/react';

export const LoadingSpinner = ({
  size,
  loadingText,
  ...props
}: ChakraSpinnerProps & {
  loadingText?: string;
}) => {
  return (
    <Flex gap={2} w={'full'} h={'full'}>
      <ChakraSpinner size={size} {...props} />
      <Text fontSize={size === 'sm' ? 'xs' : 'md'}>{loadingText}</Text>
    </Flex>
  );
};
