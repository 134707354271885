import { z } from 'zod';
import {
  fieldLinkedRowConfigSchema,
  fieldNumberConfigSchema,
  fieldSelectConfigSchema,
  fieldTypeSchema
} from './field';
import { viewStateSchema, ViewType, viewTypeSchema } from './view';

export const tableRowConfigLinkedTypeSchema = z.enum(['SINGLE_ROW', 'MULTIPLE_ROWS']);
export type TableRowConfigLinkedType = z.infer<typeof tableRowConfigLinkedTypeSchema>;

export const fieldDtoSchema = z.object({
  id: z.string(),
  name: z.string(),
  type: fieldTypeSchema,
  isEditable: z.boolean(),
  isPrimary: z.boolean(),
  linkedRowConfig: fieldLinkedRowConfigSchema.nullable(),
  selectConfig: fieldSelectConfigSchema.nullable(),
  numberConfig: fieldNumberConfigSchema.nullable()
});
export type FieldDto = z.infer<typeof fieldDtoSchema>;

export const viewDtoSchema = z.object({
  id: z.string(),
  name: z.string(),
  type: viewTypeSchema,
  state: viewStateSchema
});
export type ViewDto = z.infer<typeof viewDtoSchema>;

export const tableDtoSchema = z.object({
  id: z.string(),
  name: z.string(),
  parentTableId: z.string().nullable(),
  childTableId: z.string().nullable(),
  defaultViewId: z.string(),
  pieceOfInformationId: z.string(),
  views: z.array(viewDtoSchema),
  fields: z.array(fieldDtoSchema),
  rows: z.array(z.any()) // Assuming TableRowDto is a complex type, you might need to define a schema for it
});
export type TableDto = z.infer<typeof tableDtoSchema>;
