'use client';

import { Box, Flex, Portal, ListCollection, createListCollection, Icon } from '@chakra-ui/react';
import { Tag } from '@company/ui/components/ui/tag';
import { FieldValues } from '@company/ui/hooks/form';
import { SelectOption } from '@company/ui/types/select';
import React from 'react';
import { Controller } from 'react-hook-form';
import { FormInput, FormInputPropsWithoutChildren } from '.';
import { TagsInput } from '../tags-input';
import { Combobox } from '../combobox';

export const TagComboboxFormInput = <TFieldValues extends FieldValues>({
  form,
  name,
  root,
  label,
  labelProps,
  isDisabled,
  placeholder,
  helperText,
  helperProps,
  errorProps,
  hideError,
  size = 'md',
  options,
  isRequired,
  isReadOnly,
  isMultiSelect = false
}: FormInputPropsWithoutChildren<TFieldValues> & {
  placeholder?: string;
  options: SelectOption<unknown>[];
  isMultiSelect?: boolean;
}) => {
  const [inputValue, setInputValue] = React.useState<string>('');

  const filteredCollection = React.useMemo(() => {
    return createListCollection({
      items: options.filter(option => option.label.toLowerCase().includes(inputValue.toLowerCase()))
    });
  }, [options, inputValue]);

  return (
    <FormInput
      form={form}
      name={name}
      root={root}
      label={label}
      size={size}
      errorProps={errorProps}
      hideError={hideError}
      helperProps={helperProps}
      helperText={helperText}
      labelProps={labelProps}
      isRequired={isRequired}
      isDisabled={isDisabled}
      isReadOnly={isReadOnly}
    >
      <Controller
        control={form.control}
        name={name}
        render={({ field }) => {
          const selectedValues: string[] = React.useMemo(() => {
            return field.value === null || field.value === undefined
              ? []
              : !isMultiSelect
                ? [field.value]
                : field.value;
          }, [field.value, isMultiSelect]);

          const removeItem = React.useCallback(
            (itemValue: string) => {
              if (isMultiSelect) {
                field.onChange(selectedValues.filter(item => item !== itemValue));
              } else {
                field.onChange(null);
              }
            },
            [field, selectedValues]
          );

          const getLabel = React.useCallback(
            (value: string) => {
              return options.find(option => option.value === value)?.label;
            },
            [options]
          );

          return (
            <Combobox.Root
              asChild
              // Seems to be a bug in @ark-ui/react
              collection={filteredCollection as any}
              inputValue={inputValue}
              onInputValueChange={({ inputValue }) => setInputValue(inputValue)}
              onValueChange={({ value }) => {
                field.onChange(!isMultiSelect ? value[0] : value);
              }}
              value={selectedValues}
              readOnly={isReadOnly}
              disabled={isDisabled}
              openOnClick={true}
              multiple={isMultiSelect}
              closeOnSelect={true}
              selectionBehavior="clear"
            >
              <Combobox.Context>
                {comboBoxApi => (
                  <TagsInput.Root>
                    <TagsInput.Context>
                      {api => (
                        <>
                          <TagsInput.Control {...comboBoxApi.getControlProps()}>
                            {selectedValues.map((value, index) => (
                              <TagsInput.Item key={index} index={index} value={value}>
                                <TagsInput.ItemPreview asChild>
                                  <Tag
                                    closable={!isReadOnly && !isDisabled}
                                    onClose={() => removeItem(value)}
                                    size={size}
                                  >
                                    {getLabel(value)}
                                  </Tag>
                                </TagsInput.ItemPreview>
                                <TagsInput.ItemInput />
                                <TagsInput.HiddenInput />
                              </TagsInput.Item>
                            ))}
                            {!isReadOnly && !isDisabled && (
                              <TagsInput.Input
                                {...comboBoxApi.getInputProps()}
                                placeholder={placeholder}
                              />
                            )}
                          </TagsInput.Control>
                          <Portal>
                            <Combobox.Positioner zIndex={'1000 !important'}>
                              <Combobox.Content h={'full'} overflowY={'auto'} fontSize={'sm'}>
                                <Combobox.ItemGroup gap={1}>
                                  {filteredCollection.items.map(item => (
                                    <Combobox.Item key={item.value} item={item}>
                                      <Flex alignItems={'center'} justifyContent={'space-between'}>
                                        <Combobox.ItemText>
                                          <Flex gap={2} alignItems={'center'}>
                                            {item.icon && (
                                              <Icon as={item.icon} color={'gray.600'} />
                                            )}
                                            {item.label}
                                          </Flex>
                                        </Combobox.ItemText>
                                        <Combobox.ItemIndicator>✓</Combobox.ItemIndicator>
                                      </Flex>
                                    </Combobox.Item>
                                  ))}
                                  {filteredCollection.items.length === 0 && (
                                    <Box color={'gray.500'} px={2} py={1}>
                                      No options found
                                    </Box>
                                  )}
                                </Combobox.ItemGroup>
                              </Combobox.Content>
                            </Combobox.Positioner>
                          </Portal>
                        </>
                      )}
                    </TagsInput.Context>
                  </TagsInput.Root>
                )}
              </Combobox.Context>
            </Combobox.Root>
          );
        }}
      />
    </FormInput>
  );
};
