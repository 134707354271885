import { z } from 'zod';

export const chunkTypeSchema = z.enum([
  'TABLE',
  'DOCUMENT',
  'CONVERSATION',
  'PROJECT_NAME',
  'PROJECT_DESCRIPTION'
]);

export const chunkDtoSchema = z
  .object({
    id: z.string(),
    text: z.string(),
    type: chunkTypeSchema,
    pieceOfInformationId: z.string()
  })
  .and(
    z.discriminatedUnion('type', [
      z.object({
        type: z.literal('TABLE'),
        tableId: z.string(),
        rowId: z.string(),
        fieldId: z.string()
      }),
      z.object({
        type: z.literal('DOCUMENT')
      }),
      z.object({
        type: z.literal('CONVERSATION'),
        conversationId: z.string(),
        conversationNodeId: z.string(),
        conversationNodeElementId: z.string()
      }),
      z.object({
        type: z.literal('PROJECT_NAME'),
        projectId: z.string()
      }),
      z.object({
        type: z.literal('PROJECT_DESCRIPTION'),
        projectId: z.string()
      })
    ])
  );

export type ChunkDto = z.infer<typeof chunkDtoSchema>;
