import { z } from 'zod';

export const upsertConversationMemberSchema = z
  .object({
    id: z.string().optional(),
    type: z.enum(['AGENT', 'USER'])
  })
  .and(
    z.discriminatedUnion('type', [
      z.object({
        type: z.literal('AGENT'),
        agentId: z.string()
      }),
      z.object({
        type: z.literal('USER'),
        userId: z.string()
      })
    ])
  );

export type UpsertConversationMember = z.infer<typeof upsertConversationMemberSchema>;
