export const formatNumber = (
  number?: number | null,
  options?: {
    fractionDigits?: number;
    locale?: string;
  }
) => {
  if (isNaN(Number(number))) {
    return null;
  }

  return (
    number?.toLocaleString(options?.locale ?? 'en-US', {
      minimumFractionDigits: options?.fractionDigits,
      maximumFractionDigits: options?.fractionDigits
    }) ?? null
  );
};
