'use client';

import { Flex, Text } from '@chakra-ui/react';
import { useDisclosure } from '@company/ui/hooks/disclosure';
import { SimpleDialog } from './simple-dialog';
import { Button } from '../button';
import React from 'react';

interface AreYouSureDialogProps {
  title: string;
  description: string;
  onConfirm: () => void | Promise<void>;
  onCancel?: () => void | Promise<void>;
  children: React.ReactElement;
}

export const AreYouSureDialog = ({
  title,
  description,
  onConfirm,
  onCancel,
  children
}: AreYouSureDialogProps) => {
  const [isLoading, setIsLoading] = React.useState(false);

  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <SimpleDialog
      triggerComponent={children}
      header={{
        title
      }}
      footer={
        <Flex justify={'end'} gap={2}>
          <Button
            onClick={async () => {
              onCancel && (await onCancel());
              onClose();
            }}
            variant={'outline'}
          >
            Cancel
          </Button>
          <Button
            onClick={async () => {
              setIsLoading(true);
              await onConfirm();
              onClose();
              setIsLoading(false);
            }}
            colorScheme={'primary'}
            isLoading={isLoading}
            loadingText={'Confirming...'}
          >
            Confirm
          </Button>
        </Flex>
      }
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onClose}
    >
      <Text color={'GrayText'}>{description}</Text>
    </SimpleDialog>
  );
};
