import { Button as ChakraButton, ButtonProps as ChakraButtonProps } from '@chakra-ui/react';
import Link from 'next/link';

export const LinkButton = (props: ChakraButtonProps & { href: string }) => {
  return (
    <ChakraButton size="sm" cursor={'pointer'} {...props} asChild>
      <Link href={props.href}>{props.children}</Link>
    </ChakraButton>
  );
};
