import {
  IconButton as ChakraIconButton,
  IconButtonProps as ChakraIconButtonProps,
  Spinner
} from '@chakra-ui/react';

export const IconButton = ({
  children,
  isLoading,
  size = 'md',
  ...props
}: ChakraIconButtonProps & {
  isLoading?: boolean;
}) => {
  return (
    <ChakraIconButton size={size} cursor={'pointer'} {...props}>
      {isLoading ? <Spinner size={size} /> : children}
    </ChakraIconButton>
  );
};
