import { z } from 'zod';

export const tableRowPrimitiveDataTypeSchema = z.union([
  z.string(),
  z.number(),
  z.boolean(),
  z.date(),
  z.string().array(),
  z.null()
]);

const linkedRowRecommendationStatusSchema = z.enum([
  'PENDING',
  'IN_PROGRESS',
  'COMPLETED',
  'FAILED'
]);
export const tableRowLinkedRowTypeSchema = z.object({
  linkedRows: z
    .object({
      linkedRowId: z.string(),
      primaryFieldValue: tableRowPrimitiveDataTypeSchema,
      recommendationScore: z.number().nullable()
    })
    .array(),
  recommendationStatus: linkedRowRecommendationStatusSchema.nullish()
});
const upsertTableRowLinkedRowTypeSchema = z.object({
  linkedRows: z
    .object({
      linkedRowId: z.string(),
      primaryFieldValue: tableRowPrimitiveDataTypeSchema.nullish(),
      recommendationScore: z.number().nullish()
    })
    .array(),
  recommendationStatus: linkedRowRecommendationStatusSchema.nullish()
});
export const tableRowDataTypeSchema = z.union([
  tableRowPrimitiveDataTypeSchema,
  tableRowLinkedRowTypeSchema
]);
export const tableRowDtoSchema = z
  .object({
    id: z.string(),
    parentRowId: z.string().nullable(),
    parentTableRowId: z.string().nullable(),
    primaryFieldValue: tableRowPrimitiveDataTypeSchema.nullable()
  })
  .and(z.record(z.string(), tableRowDataTypeSchema));

export type TableRowPrimitiveDataType = z.infer<typeof tableRowPrimitiveDataTypeSchema>;
export type TableRowDataType = z.infer<typeof tableRowDataTypeSchema>;
export type TableRowData = Record<string, TableRowDataType>;
export type TableRowLinkedRowType = z.infer<typeof tableRowLinkedRowTypeSchema>;
export type TableRowDto = z.infer<typeof tableRowDtoSchema>;

export const upsertTableRowDataTypeSchema = z
  .union([tableRowPrimitiveDataTypeSchema, upsertTableRowLinkedRowTypeSchema])
  .optional();
export const upsertTableRowSchema = z
  .object({
    id: z.string(),
    parentRowId: z.string().nullish(),
    parentTableRowId: z.string().nullish()
  })
  .and(z.record(z.string(), upsertTableRowDataTypeSchema));

export type UpsertTableRowLinkedRowType = z.infer<typeof upsertTableRowLinkedRowTypeSchema>;
export type UpsertTableRowDataType = z.infer<typeof upsertTableRowDataTypeSchema>;
export type UpsertTableRow = z.infer<typeof upsertTableRowSchema>;

export const tableRowFilterSchema = z.object({
  parentTableRowId: z.string().optional(),
  rowIds: z.array(z.string()).optional(),
  isLeafRow: z.boolean().optional()
});

export type TableRowFilter = z.infer<typeof tableRowFilterSchema>;
