'use client';

import { FileUploadDropzone } from '@company/ui/components';
import { FieldValues } from '@company/ui/hooks/form';
import { Controller } from 'react-hook-form';
import { FormInput, FormInputPropsWithoutChildren } from '.';
import { FileUploadStatus, UploadedFile } from '../file/input/types';

const emptyDefaultFiles: UploadedFile[] = [];

export const FileUploadInput = <TFieldValues extends FieldValues>({
  form,
  name,
  root,
  label,
  labelProps,
  isDisabled,
  helperText,
  helperProps,
  errorProps,
  hideError,
  isRequired,
  isReadOnly,
  uploadFile,
  acceptedFileTypes,
  maxFiles = 1,
  size = 'md',
  projectId
}: FormInputPropsWithoutChildren<TFieldValues> & {
  uploadFile: (
    fileId: string,
    file: File,
    projectId: string | null
  ) => Promise<{
    id: string;
    status: FileUploadStatus;
    pieceOfInformationId: string | null;
  }>;
  maxFiles?: number;
  acceptedFileTypes?: { [key: string]: string[] };
  projectId: string | null;
}) => {
  return (
    <FormInput
      form={form}
      name={name}
      root={root}
      label={label}
      size={size}
      errorProps={errorProps}
      hideError={hideError}
      helperProps={helperProps}
      helperText={helperText}
      labelProps={labelProps}
      isRequired={isRequired}
      isDisabled={isDisabled}
    >
      <Controller
        control={form.control}
        name={name}
        render={({ field }) => (
          <FileUploadDropzone
            projectId={projectId}
            onChange={files => (maxFiles === 1 ? field.onChange(files[0]) : field.onChange(files))}
            uploadFile={uploadFile}
            acceptedFileTypes={acceptedFileTypes}
            defaultFiles={
              field.value === undefined || field.value === null
                ? emptyDefaultFiles
                : maxFiles === 1
                  ? [field.value]
                  : field.value
            }
            maxFiles={maxFiles}
            isReadOnly={isReadOnly}
          />
        )}
      />
    </FormInput>
  );
};
