'use client';

import { FileUploadDropzone } from '@company/ui/components';
import { SimpleDialog } from '@company/ui/components/dialog';
import { useDisclosure } from '@company/ui/hooks/disclosure';
import { FileUploadStatus } from './types';
import { Button } from '@chakra-ui/react';
import { useEffect, useState } from 'react';

interface UploadedFile {
  id: string;
  name: string;
  extension: string;
  pieceOfInformationId: string;
}

interface FileInputModalProps {
  onChange?: (files: UploadedFile[]) => void;
  onSave?: (files: UploadedFile[]) => void;
  uploadFile: (
    fileId: string,
    file: File,
    projectId: string | null
  ) => Promise<{ id: string; status: FileUploadStatus; pieceOfInformationId: string | null }>;
  children: React.ReactElement;
  defaultFiles: UploadedFile[];
  projectId: string | null;
}

export const FileUploadModal = ({
  children,
  onChange,
  onSave,
  uploadFile,
  defaultFiles,
  projectId
}: FileInputModalProps) => {
  const [uploadedFiles, setUploadedFiles] = useState<UploadedFile[]>(defaultFiles);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleSave = () => {
    onSave && onSave(uploadedFiles);
    onClose();
  };

  const handleChange = (files: UploadedFile[]) => {
    onChange && onChange(files);
    setUploadedFiles(files);
  };

  const close = () => {
    onClose();
    setUploadedFiles(defaultFiles);
  };

  useEffect(() => {
    setUploadedFiles(defaultFiles);
  }, [defaultFiles]);

  return (
    <SimpleDialog
      triggerComponent={children}
      header={{
        title: 'Upload your files'
      }}
      footer={
        <Button onClick={handleSave} colorScheme="primary">
          Save
        </Button>
      }
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={close}
    >
      <FileUploadDropzone
        onChange={handleChange}
        uploadFile={uploadFile}
        defaultFiles={defaultFiles}
        projectId={projectId}
      />
    </SimpleDialog>
  );
};
