import Mention from '@tiptap/extension-mention';
import { Extension } from '@tiptap/react';

export const KeyboardShortcutHandler = Extension.create({
  name: 'keyboardHandler'
});

export const MentionConversationMember = Mention.extend({
  name: 'mentionConversationMember'
});

export const MentionPieceOfInformation = Mention.extend({
  name: 'mentionPieceOfInformation'
});

export const MentionTask = Mention.extend({
  name: 'mentionTask'
});
