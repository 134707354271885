'use client';

import { Select } from '@company/ui/components';
import { Controller, FieldValues } from 'react-hook-form';
import { FormInput, FormInputPropsWithoutChildren } from '.';
import { createListCollection } from '@chakra-ui/react';

export const MultiSelectInput = <TFieldValues extends FieldValues>({
  form,
  name,
  root,
  label,
  labelProps,
  isDisabled,
  helperText,
  helperProps,
  errorProps,
  hideError,
  isRequired,
  options,
  size = 'md'
}: FormInputPropsWithoutChildren<TFieldValues> & {
  options: {
    label: string;
    value: string;
  }[];
}) => {
  const collection = createListCollection({ items: options });
  return (
    <Controller
      control={form.control}
      name={name}
      render={({ field }) => {
        return (
          <FormInput
            form={form}
            name={name}
            root={root}
            label={label}
            size={size}
            errorProps={errorProps}
            hideError={hideError}
            helperProps={helperProps}
            helperText={helperText}
            labelProps={labelProps}
            isRequired={isRequired}
            isDisabled={isDisabled}
          >
            <Select.Root
              multiple
              collection={collection}
              value={field.value}
              onValueChange={field.onChange}
            >
              <Select.Control>
                <Select.Trigger>
                  <Select.ValueText placeholder="Select a Framework" />
                </Select.Trigger>
              </Select.Control>
              <Select.Positioner>
                <Select.Content>
                  <Select.ItemGroup>
                    {collection.items.map((option, index) => (
                      <Select.Item key={index} item={option}>
                        <Select.ItemText>{option.label}</Select.ItemText>
                        <Select.ItemIndicator>✓</Select.ItemIndicator>
                      </Select.Item>
                    ))}
                  </Select.ItemGroup>
                </Select.Content>
              </Select.Positioner>
            </Select.Root>
          </FormInput>
        );
      }}
    />
  );
};
