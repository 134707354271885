import { z } from 'zod';
import { PieceOfInformationType } from '../piece-of-information';
import { EnumMap } from '../utils';
import { msg } from '@lingui/macro';
import { i18n } from '@lingui/core';

export enum FileTagName {
  I_TWO_PROJECT_X83_FILE = 'i-two-project-x83-file',
  I_TWO_PROJECT_XML_FILE = 'i-two-project-xml-file'
}

export enum TableTagName {
  I_TWO_PROJECT_TABLE = 'i-two-project-table'
}

export enum ProjectTagName {
  PRICING_CATALOG_PROJECT = 'pricing-catalog-project'
}

type TagName = FileTagName | TableTagName | ProjectTagName;

export const TAGS: EnumMap<
  TagName,
  {
    type: PieceOfInformationType;
  }
> = {
  [FileTagName.I_TWO_PROJECT_X83_FILE]: {
    id: FileTagName.I_TWO_PROJECT_X83_FILE,
    label: i18n._(msg({ id: 'iTWO X83 File' })),
    type: 'FILE'
  },
  [ProjectTagName.PRICING_CATALOG_PROJECT]: {
    id: ProjectTagName.PRICING_CATALOG_PROJECT,
    label: i18n._(msg({ id: 'Pricing Catalog' })),
    type: 'PROJECT'
  },
  [FileTagName.I_TWO_PROJECT_XML_FILE]: {
    id: FileTagName.I_TWO_PROJECT_XML_FILE,
    label: i18n._(msg({ id: 'iTWO XML File' })),
    type: 'FILE'
  },
  [TableTagName.I_TWO_PROJECT_TABLE]: {
    id: TableTagName.I_TWO_PROJECT_TABLE,
    label: i18n._(msg({ id: 'iTWO Table' })),
    type: 'TABLE'
  }
};

export const fileTagNameSchema = z.enum(['i-two-project-x83-file', 'i-two-project-xml-file']);
export const tableTagNameSchema = z.enum(['i-two-project-table']);
export const projectTagNameSchema = z.enum(['pricing-catalog-project']);
export const pieceOfInformationTagNameSchema = z.union([
  fileTagNameSchema,
  tableTagNameSchema,
  projectTagNameSchema
]);

export type PieceOfInformationTagName = z.infer<typeof pieceOfInformationTagNameSchema>;
