import { z } from 'zod';
import { pieceOfInformationDtoSchema } from '../piece-of-information';

export const agentMemoryTypeSchema = z.enum(['SHORT_TERM', 'LONG_TERM']);
export type AgentMemoryType = z.infer<typeof agentMemoryTypeSchema>;

export const agentMemoryDataTypeSchema = z.enum(['STRING', 'PIECE_OF_INFORMATION']);
export type AgentMemoryDataType = z.infer<typeof agentMemoryDataTypeSchema>;

export const agentMemoryValueSchema = z.union([z.string(), pieceOfInformationDtoSchema, z.null()]);
export type AgentMemoryValue = z.infer<typeof agentMemoryValueSchema>;
