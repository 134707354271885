export * from './html';
export * from './hash-map';

export const getPlural = (count: number, singular: string, plural: string) =>
  count > 1 ? plural : singular;

export const isHtmlString = (value: string): boolean => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(value, 'text/html');
  return Array.from(doc.body.childNodes).some(node => node.nodeType === Node.ELEMENT_NODE);
};
