import {
  Button as ChakraButton,
  ButtonProps as ChakraButtonProps,
  Spinner,
  Text
} from '@chakra-ui/react';

export const Button = ({
  children,
  isLoading,
  loadingText,
  size = 'md',
  ...props
}: ChakraButtonProps & {
  isLoading?: boolean;
  loadingText?: string;
}) => {
  return (
    <ChakraButton size={size} cursor={'pointer'} {...props}>
      {isLoading ? (
        <>
          <Spinner size={'sm'} /> <Text>{loadingText}</Text>
        </>
      ) : (
        children
      )}
    </ChakraButton>
  );
};
