import {
  TableRowDataType,
  TableRowLinkedRowType,
  UpsertTableRowDataType,
  UpsertTableRowLinkedRowType
} from '../../types';
import { formatNumber } from '../number';

export const rowValueToString = (value: TableRowDataType | undefined): string => {
  if (value === undefined) {
    return '';
  }
  if (isTableRowLinkedRowTypeArray(value)) {
    return value.linkedRows.map(linkedRow => `${linkedRow.primaryFieldValue}`).join(', ');
  }
  if (typeof value === 'number') {
    return formatNumber(value) ?? '';
  }
  if (Array.isArray(value)) {
    return value.join(', ');
  }
  if (value === null) {
    return '';
  }
  return value.toString();
};

export const isTableRowLinkedRowTypeArray = (
  value: TableRowDataType
): value is TableRowLinkedRowType => {
  return typeof value === 'object' && value !== null && 'linkedRows' in value;
};

export const isUpsertTableRowLinkedRowTypeArray = (
  value: UpsertTableRowDataType
): value is UpsertTableRowLinkedRowType => {
  return typeof value === 'object' && value !== null && 'linkedRows' in value;
};
