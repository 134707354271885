import { PieceOfInformationDtoWithStatus, UploadingFile } from '../../types';
import { uuid } from '../uuid';

export const uploadingFileToTempPieceOfInformation = (
  file: UploadingFile
): PieceOfInformationDtoWithStatus => {
  return {
    id: file.pieceOfInformationId ?? uuid(),
    status: file.status,
    type: 'FILE',
    name: file.name,
    file: {
      id: file.id,
      name: file.name,
      extension: file.extension,
      pieceOfInformationId: file.pieceOfInformationId!
    },
    createdOn: file.uploadedOn,
    itemId: file.id
  };
};
