'use client';

import * as React from 'react';

interface DisplayIfProps {
  condition: boolean;
  children: React.ReactNode;
}

export const DisplayIf = ({ children, condition }: DisplayIfProps) => {
  if (condition) {
    return <>{children}</>;
  }

  return null;
};
