'use client';

import { uuid } from '@company/common/lib';
import { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { FileUploadStatus, UploadedFile, UploadingFile } from '../components/file/input/types';

interface UseFileUploadProps {
  maxSize: number;
  acceptedFileTypes?: { [key: string]: string[] };
  maxFiles?: number;
  uploadFile: (
    fileId: string,
    file: File,
    projectId: string | null
  ) => Promise<{ id: string; status: FileUploadStatus; pieceOfInformationId: string | null }>;
  onChange: (files: UploadedFile[]) => void;
  defaultFiles: UploadedFile[];
  projectId: string | null;
}

type FileWrapper = { id: string; file: File };

const getDefaultUploadedFiles = (
  defaultFiles: { id: string; name: string; pieceOfInformationId: string }[]
): UploadingFile[] => {
  return defaultFiles.map(file => ({
    id: file.id,
    name: file.name,
    extension: file.name.split('.').pop() || '',
    size: 0,
    status: 'UPLOADED',
    file: new File([], file.name),
    uploadedOn: new Date(),
    pieceOfInformationId: file.pieceOfInformationId
  }));
};

export const useFileUpload = ({
  maxSize,
  acceptedFileTypes = {
    // 'image/*': [],
    // 'application/pdf': [],
    // 'application/msword': [],
    // 'text/csv': [],
    // 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [],
    // 'application/vnd.ms-excel.sheet.binary.macroenabled.12': [],
    // 'text/plain': []
  },
  maxFiles = 1,
  uploadFile,
  onChange,
  defaultFiles,
  projectId
}: UseFileUploadProps) => {
  const [toUploadFiles, setToUploadFiles] = useState<FileWrapper[]>([]);
  const [uploadedFiles, setUploadedFiles] = useState<UploadingFile[]>(
    getDefaultUploadedFiles(defaultFiles)
  );

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      setToUploadFiles(prevFiles => [
        ...prevFiles,
        ...acceptedFiles.map(file => ({ id: uuid(), file }))
      ]);
    },
    [setToUploadFiles]
  );

  const removeFile = (fileId: string) => {
    const nextUploadedFiles = uploadedFiles.filter(file => file.id !== fileId);
    setUploadedFiles(nextUploadedFiles);
    onChange(
      nextUploadedFiles.map(file => ({
        id: file.id!,
        name: file.name,
        extension: file.name.split('.').pop() || '',
        pieceOfInformationId: file.pieceOfInformationId!
      }))
    );
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    maxSize,
    accept: acceptedFileTypes,
    maxFiles,
    multiple: maxFiles > 1,
    onDragEnter: () => {},
    onDragOver: () => {},
    onDragLeave: () => {}
  });

  const uploadFiles = useCallback(async () => {
    const uploadedFilesBeforeUpload = uploadedFiles.slice(
      Math.max(0, uploadedFiles.length - maxFiles + 1)
    );
    setUploadedFiles([
      ...uploadedFilesBeforeUpload,
      ...toUploadFiles.map(({ id, file }) => ({
        id,
        status: 'UPLOADING' as FileUploadStatus,
        name: file.name,
        size: file.size,
        extension: file.name.split('.').pop() || '',
        file,
        pieceOfInformationId: null,
        uploadedOn: new Date()
      }))
    ]);
    const newUploadedFiles = await Promise.all(
      toUploadFiles.map(async toUploadFile => {
        const { id, status, pieceOfInformationId } = await uploadFile(
          toUploadFile.id,
          toUploadFile.file,
          projectId
        );
        return {
          id,
          status,
          name: toUploadFile.file.name,
          size: toUploadFile.file.size,
          file: toUploadFile.file,
          extension: toUploadFile.file.name.split('.').pop() || '',
          uploadedOn: new Date(),
          pieceOfInformationId
        };
      })
    );

    const nextUploadedFiles = [...uploadedFilesBeforeUpload, ...newUploadedFiles];
    setToUploadFiles([]);
    setUploadedFiles(nextUploadedFiles);
    onChange(
      nextUploadedFiles.map(file => ({
        id: file.id!,
        name: file.name,
        extension: file.extension,
        pieceOfInformationId: file.pieceOfInformationId!
      }))
    );
  }, [toUploadFiles, uploadFile]);

  useEffect(() => {
    if (toUploadFiles.length === 0) {
      return;
    }
    uploadFiles();
  }, [toUploadFiles]);

  return {
    files: uploadedFiles,
    removeFile,
    removeAllFiles: () => {
      setUploadedFiles([]);
      setToUploadFiles([]);
    },
    removeFiles: (fileIds: string[]) => {
      setUploadedFiles(prev => prev.filter(file => !fileIds.includes(file.id)));
    },
    getRootProps,
    onOpen: () => getRootProps().onClick!({} as any),
    getInputProps,
    isDragActive
  };
};
