'use client';

import { chakra, TextareaProps, useRecipe } from '@chakra-ui/react';
import TextareaAutosize from 'react-textarea-autosize';

const StyledAutoResize = chakra(TextareaAutosize);

export const TextareaAutoResize = ({ variant, size, ...props }: Omit<TextareaProps, 'style'>) => {
  const recipe = useRecipe({ key: 'textarea' });
  const styles = recipe({ size: size ?? 'md', variant: variant ?? 'filled' });
  return (
    <StyledAutoResize
      placeholder="This textarea will autoresize as you type"
      minH="initial"
      resize="none"
      overflow="hidden"
      lineHeight="inherit"
      css={styles}
      {...props}
    />
  );
};
