'use client';

import { Box, Flex, Stack } from '@chakra-ui/react';

interface AppShellProps {
  appbar?: React.ReactNode;
  sidebar?: React.ReactNode;
  main: React.ReactNode;
  breakpoint?: string;
}

export const AppShell: React.FC<AppShellProps> = ({ appbar, sidebar, main, breakpoint = 'md' }) => {
  return (
    <Box h={'100vh'} minH={'100vh'} w={'100vw'} minW={'100vw'} bgColor={'Background'}>
      <Flex h={'full'} w={'full'}>
        {sidebar && (
          <Box display={{ base: 'none', [breakpoint]: 'block' }} flexShrink={0}>
            {sidebar}
          </Box>
        )}

        <Box flexGrow={1} flexShrink={1} flexBasis={0} w={'full'} h={'full'} p={2}>
          <Box
            w={'full'}
            h={'full'}
            rounded={'xl'}
            bgColor={'white'}
            boxShadow={
              '1px -1px 3px 0 rgba(0, 0, 0, 0.05), 1px 1px 2px 0 rgba(0, 0, 0, 0.08), -1px -1px 3px 0 rgba(0, 0, 0, 0.05)'
            }
          >
            <Stack gap={0} h={'full'} w={'full'}>
              {appbar}
              <Box flexGrow={1} flexShrink={1} flexBasis={0} h={'full'} w={'full'}>
                {main}
              </Box>
            </Stack>
          </Box>
        </Box>
      </Flex>
    </Box>
  );
};
