import { removeNullish } from '../../../types/utils';
import {
  findXmlElementByName,
  textXmlConverter,
  XmlConverter,
  XmlElement,
  XmlNode
} from '../xml-converter';
import {
  ITwoWbs,
  WBSItem,
  WBSItemEstDetails,
  WBSItemEstDetailsCoCDetail,
  WBSItemEstDetailsCommodityDetail,
  WBSItemEstDetailsEstTextElement,
  WBSItemEstDetailsSubItem
} from './types/cost-estimation';

export class XmlITwoWbsConverter extends XmlConverter<ITwoWbs> {
  toJson(xml: XmlElement): ITwoWbs {
    return {
      items: (xml.elements ?? [])
        .map(element => {
          if (element.type === 'element') {
            if (element.name === 'WBSItem') {
              return new XmlWBSItemConverter().toJson(element);
            }
          }
          if (element.type === 'element' && element.name === 'NameWBSDivision') {
            return {
              type: 'WBSDivision' as const,
              name: textXmlConverter.toJson(element)
            };
          }
          return undefined;
        })
        .filter(removeNullish)
    };
  }

  toXml(json: ITwoWbs): XmlNode {
    return {
      type: 'element',
      name: 'ITEMS',
      elements: json.items
        .map(item => {
          if (item.type === 'WBSDivision') {
            return {
              type: 'element' as const,
              name: 'NameWBSDivision',
              elements: [textXmlConverter.toXml(item.name ?? '')]
            };
          } else if (item.type === 'Category' || item.type === 'Position') {
            return new XmlWBSItemConverter().toXml(item);
          }
          return undefined;
        })
        .filter(removeNullish)
    };
  }
}

export class XmlWBSItemConverter extends XmlConverter<WBSItem> {
  toJson(xml: XmlElement): WBSItem {
    const estDetails = findXmlElementByName(xml.elements ?? [], 'EstDetails');
    if (estDetails) {
      return {
        type: 'Position',
        name: textXmlConverter.toJson(
          findXmlElementByName(xml.elements, 'NameWBSItem')?.elements?.[0]
        ),
        outlineSpecs: textXmlConverter.toJson(
          findXmlElementByName(xml.elements, 'OutlineSpecs')?.elements?.[0]
        ),
        estDetails: estDetails ? new XmlWBSItemEstDetailsConverter().toJson(estDetails) : undefined
      };
    }
    return {
      type: 'Category',
      name: textXmlConverter.toJson(
        findXmlElementByName(xml.elements, 'NameWBSItem')?.elements?.[0]
      ),
      outlineSpecs: textXmlConverter.toJson(
        findXmlElementByName(xml.elements, 'OutlineSpecs')?.elements?.[0]
      ),
      estDetails: undefined
    };
  }

  toXml(value: WBSItem): XmlNode {
    return {
      type: 'element',
      name: 'WBSItem',
      elements: [
        {
          type: 'element',
          name: 'NameWBSItem',
          elements: [textXmlConverter.toXml(value.name ?? '')]
        },
        {
          type: 'element',
          name: 'OutlineSpecs',
          elements: [textXmlConverter.toXml(value.outlineSpecs ?? '')]
        },
        ...(value.estDetails ? [new XmlWBSItemEstDetailsConverter().toXml(value.estDetails)] : [])
      ]
    };
  }
}

export class XmlWBSItemEstDetailsConverter extends XmlConverter<WBSItemEstDetails> {
  toJson(xml: XmlElement): WBSItemEstDetails {
    return {
      items: (xml.elements ?? [])
        .map(element => {
          if (element.type === 'element') {
            if (element.name === 'CoCDetail') {
              return {
                ...new WBSItemEstDetailsCoCDetailConverter().toJson(element),
                type: 'CoCDetail' as const
              };
            } else if (element.name === 'SubItem') {
              return {
                ...new WBSItemEstDetailsSubItemConverter().toJson(element),
                type: 'SubItem' as const
              };
            } else if (element.name === 'CommodityDetail') {
              return {
                ...new WBSItemEstDetailsCommodityDetailConverter().toJson(element),
                type: 'CommodityDetail' as const
              };
            } else if (element.name === 'EstTextElement') {
              return {
                ...new WBSItemEstDetailsEstTextElementConverter().toJson(element),
                type: 'EstTextElement' as const
              };
            }
          }
          return undefined;
        })
        .filter(removeNullish)
    };
  }

  toXml(value: WBSItemEstDetails): XmlNode {
    return {
      type: 'element',
      name: 'EstDetails',
      elements: (
        value.items?.map(item => {
          if (item.type === 'CoCDetail') {
            return new WBSItemEstDetailsCoCDetailConverter().toXml(item);
          } else if (item.type === 'SubItem') {
            return new WBSItemEstDetailsSubItemConverter().toXml(item);
          } else if (item.type === 'CommodityDetail') {
            return new WBSItemEstDetailsCommodityDetailConverter().toXml(item);
          } else if (item.type === 'EstTextElement') {
            return new WBSItemEstDetailsEstTextElementConverter().toXml(item);
          }
          return undefined;
        }) ?? []
      ).filter(removeNullish)
    };
  }
}

enum CoCDetailXmlTags {
  NameCoC = 'NameCoC',
  DescrCoC = 'DescrCoC',
  IdentifyKey = 'IdentifyKey',
  SItemDisabled = 'SItemDisabled',
  CURCoC = 'CURCoC',
  Quantity = 'Quantity',
  Factor = 'Factor',
  FactorIsPerformanceFactor = 'FactorIsPerformanceFactor',
  URValue = 'URValue',
  CostFactor = 'CostFactor',
  CFactorCoC = 'CFactorCoC',
  QFactorCoC = 'QFactorCoC',
  FlagFixedBudget = 'FlagFixedBudget',
  BudgetUomItem = 'BudgetUomItem',
  Budget = 'Budget'
}

export class WBSItemEstDetailsCoCDetailConverter extends XmlConverter<WBSItemEstDetailsCoCDetail> {
  toJson(xml: XmlElement): WBSItemEstDetailsCoCDetail {
    const otherXmlFieldsAsJson: XmlNode[] = (xml.elements ?? [])
      .map(element => {
        if (
          element.type === 'element' &&
          !Object.values(CoCDetailXmlTags).includes(element.name as CoCDetailXmlTags)
        ) {
          return element;
        }
        return undefined;
      })
      .filter(removeNullish);

    return {
      key: textXmlConverter.toJson(
        findXmlElementByName(xml.elements, CoCDetailXmlTags.NameCoC)?.elements?.[0]
      ),
      name: textXmlConverter.toJson(
        findXmlElementByName(xml.elements, CoCDetailXmlTags.DescrCoC)?.elements?.[0]
      ),
      identifyKey: textXmlConverter.toJson(
        findXmlElementByName(xml.elements, CoCDetailXmlTags.IdentifyKey)?.elements?.[0]
      ),
      isDisabled:
        textXmlConverter.toJson(
          findXmlElementByName(xml.elements, CoCDetailXmlTags.SItemDisabled)?.elements?.[0]
        ) === '1',
      currency: textXmlConverter.toJson(
        findXmlElementByName(xml.elements, CoCDetailXmlTags.CURCoC)?.elements?.[0]
      ),
      quantity: convertToNumber(
        textXmlConverter.toJson(
          findXmlElementByName(xml.elements, CoCDetailXmlTags.Quantity)?.elements?.[0]
        )
      ),
      factor: convertToNumber(
        textXmlConverter.toJson(
          findXmlElementByName(xml.elements, CoCDetailXmlTags.Factor)?.elements?.[0]
        )
      ),
      factorIsPerformanceFactor: convertToBoolean(
        textXmlConverter.toJson(
          findXmlElementByName(xml.elements, CoCDetailXmlTags.FactorIsPerformanceFactor)
            ?.elements?.[0]
        )
      ),
      urValue: convertToNumber(
        textXmlConverter.toJson(
          findXmlElementByName(xml.elements, CoCDetailXmlTags.URValue)?.elements?.[0]
        )
      ),
      costFactor: convertToNumber(
        textXmlConverter.toJson(
          findXmlElementByName(xml.elements, CoCDetailXmlTags.CostFactor)?.elements?.[0]
        )
      ),
      cFactorCoc: convertToNumber(
        textXmlConverter.toJson(
          findXmlElementByName(xml.elements, CoCDetailXmlTags.CFactorCoC)?.elements?.[0]
        )
      ),
      qFactorCoc: convertToNumber(
        textXmlConverter.toJson(
          findXmlElementByName(xml.elements, CoCDetailXmlTags.QFactorCoC)?.elements?.[0]
        )
      ),
      flagFixedBudget: convertToBoolean(
        textXmlConverter.toJson(
          findXmlElementByName(xml.elements, CoCDetailXmlTags.FlagFixedBudget)?.elements?.[0]
        )
      ),
      budgetUomItem: textXmlConverter.toJson(
        findXmlElementByName(xml.elements, CoCDetailXmlTags.BudgetUomItem)?.elements?.[0]
      ),
      budget: textXmlConverter.toJson(
        findXmlElementByName(xml.elements, CoCDetailXmlTags.Budget)?.elements?.[0]
      ),
      otherXmlFieldsAsJson:
        otherXmlFieldsAsJson.length > 0 ? JSON.stringify(otherXmlFieldsAsJson) : undefined
    };
  }

  toXml(value: WBSItemEstDetailsCoCDetail): XmlNode {
    return {
      type: 'element',
      name: 'CoCDetail',
      elements: [
        {
          type: 'element',
          name: CoCDetailXmlTags.NameCoC,
          elements: [textXmlConverter.toXml(value.key ?? '')]
        },
        {
          type: 'element',
          name: CoCDetailXmlTags.DescrCoC,
          elements: [textXmlConverter.toXml(value.name ?? '')]
        },
        {
          type: 'element',
          name: CoCDetailXmlTags.IdentifyKey,
          elements: [textXmlConverter.toXml(value.identifyKey ?? '')]
        },
        {
          type: 'element',
          name: CoCDetailXmlTags.SItemDisabled,
          elements: [textXmlConverter.toXml(value.isDisabled ? '1' : '0')]
        },
        {
          type: 'element',
          name: CoCDetailXmlTags.CURCoC,
          elements: [textXmlConverter.toXml(value.currency ?? '')]
        },
        {
          type: 'element',
          name: CoCDetailXmlTags.Quantity,
          elements: [textXmlConverter.toXml(value.quantity?.toString() ?? '')]
        },
        {
          type: 'element',
          name: CoCDetailXmlTags.Factor,
          elements: [textXmlConverter.toXml(value.factor?.toString() ?? '')]
        },
        {
          type: 'element',
          name: CoCDetailXmlTags.FactorIsPerformanceFactor,
          elements: [textXmlConverter.toXml(value.factorIsPerformanceFactor ? '1' : '0')]
        },
        {
          type: 'element',
          name: CoCDetailXmlTags.URValue,
          elements: [textXmlConverter.toXml(value.urValue?.toString() ?? '')]
        },
        {
          type: 'element',
          name: CoCDetailXmlTags.CostFactor,
          elements: [textXmlConverter.toXml(value.costFactor?.toString() ?? '')]
        },
        {
          type: 'element',
          name: CoCDetailXmlTags.CFactorCoC,
          elements: [textXmlConverter.toXml(value.cFactorCoc?.toString() ?? '')]
        },
        {
          type: 'element',
          name: CoCDetailXmlTags.QFactorCoC,
          elements: [textXmlConverter.toXml(value.qFactorCoc?.toString() ?? '')]
        },
        {
          type: 'element',
          name: CoCDetailXmlTags.FlagFixedBudget,
          elements: [textXmlConverter.toXml(value.flagFixedBudget ? '1' : '0')]
        },
        {
          type: 'element',
          name: CoCDetailXmlTags.BudgetUomItem,
          elements: [textXmlConverter.toXml(value.budgetUomItem?.toString() ?? '')]
        },
        {
          type: 'element',
          name: CoCDetailXmlTags.Budget,
          elements: [textXmlConverter.toXml(value.budget?.toString() ?? '')]
        },
        ...(value.otherXmlFieldsAsJson ? JSON.parse(value.otherXmlFieldsAsJson) : [])
      ]
    };
  }
}

enum CommodityDetailXmlTags {
  NameCommodity = 'NameCommodity',
  DescrCOMMODITY = 'DescrCOMMODITY',
  URValue = 'URValue',
  Currency = 'Currency',
  IdentifyKey = 'IdentifyKey',
  SItemDisabled = 'SItemDisabled',
  Quantity = 'Quantity',
  Factor = 'Factor',
  CostFactor = 'CostFactor',
  FlagFixedBudget = 'FlagFixedBudget',
  FactorIsPerformanceFactor = 'FactorIsPerformanceFactor',
  BudgetUomItem = 'BudgetUomItem',
  Budget = 'Budget',
  EstDetails = 'EstDetails'
}

export class WBSItemEstDetailsCommodityDetailConverter extends XmlConverter<WBSItemEstDetailsCommodityDetail> {
  toJson(xml: XmlElement): WBSItemEstDetailsCommodityDetail {
    const estDetails = findXmlElementByName(xml.elements, CommodityDetailXmlTags.EstDetails);
    const otherXmlFieldsAsJson: XmlNode[] = (xml.elements ?? [])
      .map(element => {
        if (
          element.type === 'element' &&
          !Object.values(CommodityDetailXmlTags).includes(element.name as CommodityDetailXmlTags)
        ) {
          return element;
        }
        return undefined;
      })
      .filter(removeNullish);

    return {
      nameCommodity: textXmlConverter.toJson(
        findXmlElementByName(xml.elements, CommodityDetailXmlTags.NameCommodity)?.elements?.[0]
      ),
      descrCommodity: textXmlConverter.toJson(
        findXmlElementByName(xml.elements, CommodityDetailXmlTags.DescrCOMMODITY)?.elements?.[0]
      ),
      urValue: convertToNumber(
        textXmlConverter.toJson(
          findXmlElementByName(xml.elements, CommodityDetailXmlTags.URValue)?.elements?.[0]
        )
      ),
      currency: textXmlConverter.toJson(
        findXmlElementByName(xml.elements, CommodityDetailXmlTags.Currency)?.elements?.[0]
      ),
      identifyKey: textXmlConverter.toJson(
        findXmlElementByName(xml.elements, CommodityDetailXmlTags.IdentifyKey)?.elements?.[0]
      ),
      isDisabled:
        textXmlConverter.toJson(
          findXmlElementByName(xml.elements, CommodityDetailXmlTags.SItemDisabled)?.elements?.[0]
        ) === '1',
      quantity: convertToNumber(
        textXmlConverter.toJson(
          findXmlElementByName(xml.elements, CommodityDetailXmlTags.Quantity)?.elements?.[0]
        )
      ),
      factor: convertToNumber(
        textXmlConverter.toJson(
          findXmlElementByName(xml.elements, CommodityDetailXmlTags.Factor)?.elements?.[0]
        )
      ),
      factorIsPerformanceFactor: convertToBoolean(
        textXmlConverter.toJson(
          findXmlElementByName(xml.elements, CommodityDetailXmlTags.FactorIsPerformanceFactor)
            ?.elements?.[0]
        )
      ),
      costFactor: convertToNumber(
        textXmlConverter.toJson(
          findXmlElementByName(xml.elements, CommodityDetailXmlTags.CostFactor)?.elements?.[0]
        )
      ),
      flagFixedBudget: convertToBoolean(
        textXmlConverter.toJson(
          findXmlElementByName(xml.elements, CommodityDetailXmlTags.FlagFixedBudget)?.elements?.[0]
        )
      ),
      budgetUomItem: textXmlConverter.toJson(
        findXmlElementByName(xml.elements, CommodityDetailXmlTags.BudgetUomItem)?.elements?.[0]
      ),
      budget: textXmlConverter.toJson(
        findXmlElementByName(xml.elements, CommodityDetailXmlTags.Budget)?.elements?.[0]
      ),
      estDetails: estDetails ? new XmlWBSItemEstDetailsConverter().toJson(estDetails) : undefined,
      otherXmlFieldsAsJson:
        otherXmlFieldsAsJson.length > 0 ? JSON.stringify(otherXmlFieldsAsJson) : undefined
    };
  }

  toXml(value: WBSItemEstDetailsCommodityDetail): XmlNode {
    return {
      type: 'element',
      name: 'CommodityDetail',
      elements: [
        {
          type: 'element',
          name: CommodityDetailXmlTags.NameCommodity,
          elements: [textXmlConverter.toXml(value.nameCommodity ?? '')]
        },
        {
          type: 'element',
          name: CommodityDetailXmlTags.DescrCOMMODITY,
          elements: [textXmlConverter.toXml(value.descrCommodity ?? '')]
        },
        {
          type: 'element',
          name: CommodityDetailXmlTags.URValue,
          elements: [textXmlConverter.toXml(value.urValue?.toString() ?? '')]
        },
        {
          type: 'element',
          name: CommodityDetailXmlTags.Currency,
          elements: [textXmlConverter.toXml(value.currency ?? '')]
        },
        {
          type: 'element',
          name: CommodityDetailXmlTags.IdentifyKey,
          elements: [textXmlConverter.toXml(value.identifyKey ?? '')]
        },
        {
          type: 'element',
          name: CommodityDetailXmlTags.SItemDisabled,
          elements: [textXmlConverter.toXml(value.isDisabled ? '1' : '0')]
        },
        {
          type: 'element',
          name: CommodityDetailXmlTags.Quantity,
          elements: [textXmlConverter.toXml(value.quantity?.toString() ?? '')]
        },
        {
          type: 'element',
          name: CommodityDetailXmlTags.Factor,
          elements: [textXmlConverter.toXml(value.factor?.toString() ?? '')]
        },
        {
          type: 'element',
          name: CommodityDetailXmlTags.FactorIsPerformanceFactor,
          elements: [textXmlConverter.toXml(value.factorIsPerformanceFactor ? '1' : '0')]
        },
        {
          type: 'element',
          name: CommodityDetailXmlTags.CostFactor,
          elements: [textXmlConverter.toXml(value.costFactor?.toString() ?? '')]
        },
        {
          type: 'element',
          name: CommodityDetailXmlTags.FlagFixedBudget,
          elements: [textXmlConverter.toXml(value.flagFixedBudget ? '1' : '0')]
        },
        {
          type: 'element',
          name: CommodityDetailXmlTags.BudgetUomItem,
          elements: [textXmlConverter.toXml(value.budgetUomItem ?? '')]
        },
        {
          type: 'element',
          name: CommodityDetailXmlTags.Budget,
          elements: [textXmlConverter.toXml(value.budget ?? '')]
        },
        ...(value.otherXmlFieldsAsJson ? JSON.parse(value.otherXmlFieldsAsJson) : []),
        ...(value.estDetails ? [new XmlWBSItemEstDetailsConverter().toXml(value.estDetails)] : [])
      ]
    };
  }
}

enum SubItemXmlTags {
  SubitemNumber = 'SubitemNumber',
  Quantity = 'Quantity',
  Factor = 'Factor',
  CostFactor = 'CostFactor',
  FlagFixedBudget = 'FlagFixedBudget',
  FactorIsPerformanceFactor = 'FactorIsPerformanceFactor',
  BudgetUomItem = 'BudgetUomItem',
  Budget = 'Budget',
  Compressed = 'Compressed',
  Text = 'Text',
  UnitOfMeasure = 'UnitOfMeasure',
  SItemNo = 'SItemNo',
  SItemLSum = 'SItemLSum',
  SItemLSumAbs = 'SItemLSumAbs',
  SItemDisabled = 'SItemDisabled',
  SItemReserve = 'SItemReserve',
  SPPhase = 'SPPhase',
  EstDetails = 'EstDetails'
}

export class WBSItemEstDetailsSubItemConverter extends XmlConverter<WBSItemEstDetailsSubItem> {
  toJson(xml: XmlElement): WBSItemEstDetailsSubItem {
    const estDetails = findXmlElementByName(xml.elements, SubItemXmlTags.EstDetails);
    const otherXmlFieldsAsJson: XmlNode[] = (xml.elements ?? [])
      .map(element => {
        if (
          element.type === 'element' &&
          !Object.values(SubItemXmlTags).includes(element.name as SubItemXmlTags)
        ) {
          return element;
        }
        return undefined;
      })
      .filter(removeNullish);

    return {
      text: textXmlConverter.toJson(
        findXmlElementByName(xml.elements, SubItemXmlTags.Text)?.elements?.[0]
      ),
      subItemNumber: textXmlConverter.toJson(
        findXmlElementByName(xml.elements, SubItemXmlTags.SubitemNumber)?.elements?.[0]
      ),
      quantity: convertToNumber(
        textXmlConverter.toJson(
          findXmlElementByName(xml.elements, SubItemXmlTags.Quantity)?.elements?.[0]
        )
      ),
      unitOfMeasure: textXmlConverter.toJson(
        findXmlElementByName(xml.elements, SubItemXmlTags.UnitOfMeasure)?.elements?.[0]
      ),
      factor: convertToNumber(
        textXmlConverter.toJson(
          findXmlElementByName(xml.elements, SubItemXmlTags.Factor)?.elements?.[0]
        )
      ),
      costFactor: convertToNumber(
        textXmlConverter.toJson(
          findXmlElementByName(xml.elements, SubItemXmlTags.CostFactor)?.elements?.[0]
        )
      ),
      budgetUomItem: textXmlConverter.toJson(
        findXmlElementByName(xml.elements, SubItemXmlTags.BudgetUomItem)?.elements?.[0]
      ),
      budget: textXmlConverter.toJson(
        findXmlElementByName(xml.elements, SubItemXmlTags.Budget)?.elements?.[0]
      ),
      flagFixedBudget: convertToBoolean(
        textXmlConverter.toJson(
          findXmlElementByName(xml.elements, SubItemXmlTags.FlagFixedBudget)?.elements?.[0]
        )
      ),
      factorIsPerformanceFactor: convertToBoolean(
        textXmlConverter.toJson(
          findXmlElementByName(xml.elements, SubItemXmlTags.FactorIsPerformanceFactor)
            ?.elements?.[0]
        )
      ),
      compressed: convertToBoolean(
        textXmlConverter.toJson(
          findXmlElementByName(xml.elements, SubItemXmlTags.Compressed)?.elements?.[0]
        )
      ),
      sItemLSum: textXmlConverter.toJson(
        findXmlElementByName(xml.elements, SubItemXmlTags.SItemLSum)?.elements?.[0]
      ),
      sItemLSumAbs: textXmlConverter.toJson(
        findXmlElementByName(xml.elements, SubItemXmlTags.SItemLSumAbs)?.elements?.[0]
      ),
      sItemDisabled: convertToBoolean(
        textXmlConverter.toJson(
          findXmlElementByName(xml.elements, SubItemXmlTags.SItemDisabled)?.elements?.[0]
        )
      ),
      sItemNo: textXmlConverter.toJson(
        findXmlElementByName(xml.elements, SubItemXmlTags.SItemNo)?.elements?.[0]
      ),
      sItemReserve: textXmlConverter.toJson(
        findXmlElementByName(xml.elements, SubItemXmlTags.SItemReserve)?.elements?.[0]
      ),
      spPhase: textXmlConverter.toJson(
        findXmlElementByName(xml.elements, SubItemXmlTags.SPPhase)?.elements?.[0]
      ),
      otherXmlFieldsAsJson:
        otherXmlFieldsAsJson.length > 0 ? JSON.stringify(otherXmlFieldsAsJson) : undefined,
      estDetails: estDetails
        ? {
            items: new XmlWBSItemEstDetailsConverter().toJson(estDetails).items
          }
        : undefined
    };
  }

  toXml(value: WBSItemEstDetailsSubItem): XmlNode {
    const subItemsXml = value.estDetails
      ? new XmlWBSItemEstDetailsConverter().toXml(value.estDetails)
      : undefined;

    return {
      type: 'element',
      name: 'SubItem',
      elements: [
        {
          type: 'element',
          name: SubItemXmlTags.SubitemNumber,
          elements: [textXmlConverter.toXml(value.subItemNumber ?? '')]
        },
        {
          type: 'element',
          name: SubItemXmlTags.Quantity,
          elements: [textXmlConverter.toXml(value.quantity?.toString() ?? '')]
        },
        {
          type: 'element',
          name: SubItemXmlTags.Factor,
          elements: [textXmlConverter.toXml(value.factor?.toString() ?? '')]
        },
        {
          type: 'element',
          name: SubItemXmlTags.FactorIsPerformanceFactor,
          elements: [textXmlConverter.toXml(value.factorIsPerformanceFactor ? '1' : '0')]
        },
        {
          type: 'element',
          name: SubItemXmlTags.CostFactor,
          elements: [textXmlConverter.toXml(value.costFactor?.toString() ?? '')]
        },
        {
          type: 'element',
          name: SubItemXmlTags.FlagFixedBudget,
          elements: [textXmlConverter.toXml(value.flagFixedBudget ? '1' : '0')]
        },
        {
          type: 'element',
          name: SubItemXmlTags.BudgetUomItem,
          elements: [textXmlConverter.toXml(value.budgetUomItem ?? '')]
        },
        {
          type: 'element',
          name: SubItemXmlTags.Budget,
          elements: [textXmlConverter.toXml(value.budget ?? '')]
        },
        {
          type: 'element',
          name: SubItemXmlTags.Text,
          elements: [textXmlConverter.toXml(value.text ?? '')]
        },
        {
          type: 'element',
          name: SubItemXmlTags.UnitOfMeasure,
          elements: [textXmlConverter.toXml(value.unitOfMeasure ?? '')]
        },
        {
          type: 'element',
          name: SubItemXmlTags.SItemNo,
          elements: [textXmlConverter.toXml(value.sItemNo ?? '')]
        },
        {
          type: 'element',
          name: SubItemXmlTags.SItemLSum,
          elements: [textXmlConverter.toXml(value.sItemLSum ?? '')]
        },
        {
          type: 'element',
          name: SubItemXmlTags.SItemLSumAbs,
          elements: [textXmlConverter.toXml(value.sItemLSumAbs ?? '')]
        },
        {
          type: 'element',
          name: SubItemXmlTags.SItemDisabled,
          elements: [textXmlConverter.toXml(value.sItemDisabled ? '1' : '0')]
        },
        {
          type: 'element',
          name: SubItemXmlTags.Compressed,
          elements: [textXmlConverter.toXml(value.compressed ? '1' : '0')]
        },
        {
          type: 'element',
          name: SubItemXmlTags.SItemReserve,
          elements: [textXmlConverter.toXml(value.sItemReserve ?? '')]
        },
        {
          type: 'element',
          name: SubItemXmlTags.SPPhase,
          elements: [textXmlConverter.toXml(value.spPhase ?? '')]
        },
        ...(value.otherXmlFieldsAsJson ? JSON.parse(value.otherXmlFieldsAsJson) : []),
        ...(subItemsXml ? [subItemsXml] : [])
      ]
    };
  }
}

enum EstTextElementXmlTags {
  Text = 'Text'
}

export class WBSItemEstDetailsEstTextElementConverter extends XmlConverter<WBSItemEstDetailsEstTextElement> {
  toJson(xml: XmlElement): WBSItemEstDetailsEstTextElement {
    const otherXmlFieldsAsJson: XmlNode[] = (xml.elements ?? [])
      .map(element => {
        if (
          element.type === 'element' &&
          !Object.values(EstTextElementXmlTags).includes(element.name as EstTextElementXmlTags)
        ) {
          return element;
        }
        return undefined;
      })
      .filter(removeNullish);
    return {
      text: textXmlConverter.toJson(
        findXmlElementByName(xml.elements, EstTextElementXmlTags.Text)?.elements?.[0]
      ),
      otherXmlFieldsAsJson:
        otherXmlFieldsAsJson.length > 0 ? JSON.stringify(otherXmlFieldsAsJson) : undefined
    };
  }

  toXml(value: WBSItemEstDetailsEstTextElement): XmlNode {
    return {
      type: 'element',
      name: 'EstTextElement',
      elements: [
        {
          type: 'element',
          name: EstTextElementXmlTags.Text,
          elements: [textXmlConverter.toXml(value.text ?? '')]
        },
        ...(value.otherXmlFieldsAsJson ? JSON.parse(value.otherXmlFieldsAsJson) : [])
      ]
    };
  }
}

const convertToBoolean = (value: string | undefined): boolean | undefined => {
  if (value === '1') {
    return true;
  } else if (value === '0') {
    return false;
  }
  return undefined;
};

export const convertToNumber = (value: string | undefined): number | undefined => {
  if (value) {
    return Number(value);
  }
  return undefined;
};
