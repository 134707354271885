'use client';

import { CardRoot, CardRootProps } from '@chakra-ui/react';
import Link from 'next/link';
import { ReactNode } from 'react';

interface ClickableCardProps extends CardRootProps {
  children: ReactNode;
  href?: string;
}

export const ClickableCard = ({ children, href, ...props }: ClickableCardProps) => {
  const cardRoot = (
    <CardRoot
      w={'100%'}
      h={'100%'}
      shadow={'xs'}
      cursor={'pointer'}
      _hover={{ bgColor: 'gray.50' }}
      userSelect={'none'}
      {...props}
    >
      {children}
    </CardRoot>
  );

  if (href) {
    return <Link href={href}>{cardRoot}</Link>;
  }
  return cardRoot;
};
