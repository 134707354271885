import { hash } from '../crypto';

export const createHashToTextMap = (strings: string[]) => {
  return strings.reduce(
    (acc, str) => {
      acc[hash(str)] = str;
      return acc;
    },
    {} as Record<string, string>
  );
};
