import { z } from 'zod';

export const viewFieldStateSchema = z.object({
  id: z.string(),
  sortKey: z.string(),
  width: z.number().optional()
});

const gridViewStateSchema = z.object({
  type: z.literal('GRID'),
  fields: z.array(viewFieldStateSchema)
});

export const viewTypeSchema = z.enum(['GRID']);

export const viewStateSchema = z.discriminatedUnion('type', [gridViewStateSchema]);

export type ViewFieldState = z.infer<typeof viewFieldStateSchema>;
export type GridViewState = z.infer<typeof gridViewStateSchema>;
export type ViewType = z.infer<typeof viewTypeSchema>;
export type ViewState<TType extends ViewType = 'GRID'> = z.infer<typeof viewStateSchema> & {
  type: TType;
};
