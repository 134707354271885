'use client';

import { Link, LinkProps } from '@chakra-ui/react';
import NextLink from 'next/link';

export const Anchor = (props: LinkProps) => {
  return (
    <Link as={NextLink} href={props.href} color={'blue.500'} {...props}>
      {props.children}
    </Link>
  );
};

export const AnchorExternal = (props: LinkProps) => {
  return (
    <Link href={props.href} color={'blue.500'} {...props}>
      {props.children}
    </Link>
  );
};

export const NonHrefAnchor = (props: LinkProps) => {
  return (
    <Link {...props} color={'blue.500'}>
      {props.children}
    </Link>
  );
};
