import { z } from 'zod';
import { EnumMap } from '../utils/enum-map';
import { getNativeEnumFromObject } from '../utils/zod-object-to-enum';

export enum FieldTypeEnum {
  SHORT_TEXT = 'SHORT_TEXT',
  LONG_TEXT = 'LONG_TEXT',
  NUMBER = 'NUMBER',
  SINGLE_SELECT = 'SINGLE_SELECT',
  MULTI_SELECT = 'MULTI_SELECT',
  DATE = 'DATE',
  CHECKBOX = 'CHECKBOX',
  FILE_ATTACHMENT = 'FILE_ATTACHMENT',
  LINKED_ROW = 'LINKED_ROW'
}

export type FieldType = keyof typeof FieldTypeEnum;

export const FIELD_TYPES: EnumMap<FieldType> = {
  SHORT_TEXT: { id: 'SHORT_TEXT', label: 'Short Text' },
  LONG_TEXT: { id: 'LONG_TEXT', label: 'Long Text' },
  NUMBER: { id: 'NUMBER', label: 'Number' },
  SINGLE_SELECT: { id: 'SINGLE_SELECT', label: 'Single Select' },
  MULTI_SELECT: { id: 'MULTI_SELECT', label: 'Multi Select' },
  DATE: { id: 'DATE', label: 'Date' },
  CHECKBOX: { id: 'CHECKBOX', label: 'Checkbox' },
  FILE_ATTACHMENT: { id: 'FILE_ATTACHMENT', label: 'File Attachment' },
  LINKED_ROW: { id: 'LINKED_ROW', label: 'Linked Row' }
};
export const fieldTypeSchema = z.nativeEnum(getNativeEnumFromObject(FIELD_TYPES));

export const fieldLinkedRowConfigSchema = z.object({
  type: z.enum(['SINGLE_ROW', 'MULTIPLE_ROWS']),
  tableId: z.string(),
  useAi: z.boolean(),
  onlyLinkLeafRows: z.boolean(),
  aiConfig: z
    .object({
      prompt: z.string().optional(),
      fieldMappings: z.array(
        z.object({
          currentTableFieldId: z.string(),
          linkedTableFieldId: z.string(),
          scoreWeight: z.number()
        })
      ),
      otherFieldsToCompare: z
        .object({
          currentTableFieldIds: z.array(z.string()).optional(),
          linkedTableFieldIds: z.array(z.string()).optional(),
          scoreWeight: z.number()
        })
        .optional(),
      autofillIfScoreAbove: z.number().optional()
    })
    .optional()
});
export type FieldLinkedRowConfig = z.infer<typeof fieldLinkedRowConfigSchema>;

export const fieldSelectConfigSchema = z.object({
  options: z.array(
    z.object({
      id: z.string(),
      value: z.string(),
      color: z.string()
    })
  )
});
export type FieldSelectConfig = z.infer<typeof fieldSelectConfigSchema>;

export const fieldNumberConfigSchema = z.object({
  min: z.number().optional(),
  max: z.number().optional(),
  decimalPlaces: z.number().optional()
});
export type FieldNumberConfig = z.infer<typeof fieldNumberConfigSchema>;

export const addFieldToTableSchema = z.object({
  name: z.string(),
  type: fieldTypeSchema,
  isEditable: z.boolean(),
  linkedRowConfig: fieldLinkedRowConfigSchema.nullable(),
  selectConfig: fieldSelectConfigSchema.nullable(),
  numberConfig: fieldNumberConfigSchema.nullable()
});
export type AddFieldToTable = z.infer<typeof addFieldToTableSchema>;

export const editFieldInTableSchema = z.object({
  id: z.string(),
  name: z.string(),
  linkedRowConfig: fieldLinkedRowConfigSchema.nullable(),
  selectConfig: fieldSelectConfigSchema.nullable(),
  numberConfig: fieldNumberConfigSchema.nullable()
});
export type EditFieldInTable = z.infer<typeof editFieldInTableSchema>;

export const deleteFieldSchema = z.object({
  id: z.string()
});
export type DeleteField = z.infer<typeof deleteFieldSchema>;

export const makeFieldPrimarySchema = z.object({
  id: z.string()
});
export type MakeFieldPrimary = z.infer<typeof makeFieldPrimarySchema>;

export const reservedFieldIds = [
  'id',
  'parentRowId',
  'parentTableRowId',
  'primaryFieldValue'
] as const;
