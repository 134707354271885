'use client';

import { Box, Flex, Heading } from '@chakra-ui/react';

export function PageHeading({
  title,
  subTitle,
  rightElement
}: {
  title: {
    icon?: React.ReactNode;
    text: string;
  };
  subTitle?: string | null;
  rightElement?: React.ReactNode;
}) {
  return (
    <Box>
      <Flex h={'100%'} align={'center'}>
        <Box>
          <Heading fontSize={'2xl'} fontWeight={'semibold'} mt={-1}>
            <Flex align={'center'} gap={2}>
              {title.icon}
              {title.text}
            </Flex>
          </Heading>
          {subTitle && (
            <Box fontSize={'sm'} color={'GrayText'} pt={1}>
              {subTitle}
            </Box>
          )}
        </Box>
        {rightElement && <Box ml="auto">{rightElement}</Box>}
      </Flex>
    </Box>
  );
}
