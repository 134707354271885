'use client';

import { Breadcrumb, Button } from '@chakra-ui/react';

export interface BreadcrumbItem {
  label: string;
  href: string;
}

export const Breadcrumbs = ({ breadcrumbs }: { breadcrumbs: BreadcrumbItem[] }) => {
  return (
    <Breadcrumb.Root>
      {breadcrumbs.map(({ label, href }, index) => (
        <Breadcrumb.Item key={index}>
          <Breadcrumb.Link href={href}>
            <Button
              size={'xs'}
              fontSize={'14px'}
              color={'gray.600'}
              fontWeight={'normal'}
              variant={'ghost'}
            >
              {label}
            </Button>
          </Breadcrumb.Link>
        </Breadcrumb.Item>
      ))}
    </Breadcrumb.Root>
  );
};
