import { z } from 'zod';
import { FileUploadConfig, fileUploadConfigSchema, uploadedFileSchema } from '../files';
import { PieceOfInformationType } from '../piece-of-information';
import { selectConfigSchema } from '../select';

export type AgentInformationRequestDto = {
  id: string;
  informationRequestId: string;
  conversationReferenceId: string;
  inputType: PieceOfInformationType;
  label: string;
  helperText: string | null;
  fileUploadConfig: FileUploadConfig | null;
  isRequired: boolean;
  placeholder: string | null;
};

const formInputValueSchema = z.union([
  z.string(),
  z.array(z.string()),
  uploadedFileSchema,
  z.array(uploadedFileSchema),
  z.null()
]);

const inputTypeSchema = z.enum(['FILE', 'PROJECT']);

export const updateAgentInformationRequestValueSchema = z.object({
  referenceId: z.string(),
  inputType: inputTypeSchema,
  value: formInputValueSchema
});

export const agentInformationRequestFieldSchema = z
  .object({
    referenceId: z.string(),
    inputType: inputTypeSchema,
    label: z.string(),
    helperText: z.string().nullish(),
    placeholder: z.string().nullish(),
    isRequired: z.boolean()
  })
  .and(
    z.discriminatedUnion('inputType', [
      z.object({
        inputType: z.literal('FILE'),
        fileUploadConfig: fileUploadConfigSchema
      }),
      z.object({
        inputType: z.literal('PROJECT'),
        selectConfig: selectConfigSchema
      })
    ])
  );
export const agentInformationRequestFieldWithValueSchema = agentInformationRequestFieldSchema.and(
  z.object({
    value: formInputValueSchema
  })
);

export type AgentInformationRequestValue = z.infer<typeof formInputValueSchema>;
export type UpdateAgentInformationRequestWithValue = z.infer<
  typeof updateAgentInformationRequestValueSchema
>;
export type AgentInformationRequest = z.infer<typeof agentInformationRequestFieldSchema>;
export type AgentInformationRequestWithValue = z.infer<
  typeof agentInformationRequestFieldWithValueSchema
>;
