import { z } from 'zod';

export const singleSelectConfigSchema = z.object({
  type: z.literal('SINGLE_SELECT')
});

export const multiSelectConfigSchema = z.object({
  type: z.literal('MULTI_SELECT'),
  minItems: z.number().optional(),
  maxItems: z.number().optional()
});

export const selectConfigSchema = z.discriminatedUnion('type', [
  singleSelectConfigSchema,
  multiSelectConfigSchema
]);

export type SelectConfig = z.infer<typeof selectConfigSchema>;
