import type { BoxProps } from '@chakra-ui/react';
import { Group, InputElement } from '@chakra-ui/react';
import { cloneElement, forwardRef, useMemo } from 'react';

export interface VerticalInputGroupProps extends BoxProps {
  topElement?: React.ReactNode;
  topElementProps?: BoxProps;
  bottomElement?: React.ReactNode;
  bottomElementProps?: BoxProps;
  children: React.ReactElement;
}

export const VerticalInputGroup = forwardRef<HTMLDivElement, VerticalInputGroupProps>(
  function VerticalInputGroup(props, ref) {
    const { topElement, bottomElement, children, topElementProps, bottomElementProps, ...rest } =
      props;

    const topElementHeight = useMemo(() => {
      return topElement ? `calc(${topElementProps?.height || '2rem'} + 0.25rem)` : '0.25rem';
    }, [topElement, topElementProps]);

    const bottomElementHeight = useMemo(() => {
      return bottomElement ? `calc(${bottomElementProps?.height || '2rem'} + 0.25rem)` : '0.25rem';
    }, [bottomElement, bottomElementProps]);

    return (
      <Group ref={ref} direction="column" {...rest}>
        {topElement && (
          <InputElement pointerEvents="none" w="full" px={0}>
            {topElement}
          </InputElement>
        )}
        {cloneElement(children, {
          ...(topElement ? { paddingTop: topElementHeight } : {}),
          ...(bottomElement ? { paddingBottom: bottomElementHeight } : {}),
          ...(children.props as React.HTMLAttributes<HTMLDivElement>)
        })}
        {bottomElement && (
          <InputElement pointerEvents={'none'} alignItems={'flex-end'} w="full" px={0}>
            {bottomElement}
          </InputElement>
        )}
      </Group>
    );
  }
);

export interface HorizontalInputGroupProps extends BoxProps {
  startElement?: React.ReactNode;
  startElementProps?: BoxProps;
  endElement?: React.ReactNode;
  endElementProps?: BoxProps;
  children: React.ReactElement;
}
export const HorizontalInputGroup = forwardRef<HTMLDivElement, HorizontalInputGroupProps>(
  function HorizontalInputGroup(props, ref) {
    const { startElement, startElementProps, endElement, endElementProps, children, ...rest } =
      props;

    return (
      <Group ref={ref} {...rest}>
        {startElement && (
          <InputElement pointerEvents="none" {...startElementProps}>
            {startElement}
          </InputElement>
        )}
        {cloneElement(children, {
          ...(startElement && { ps: 'calc(var(--input-height) - 6px)' }),
          ...(endElement && { pe: 'calc(var(--input-height) - 6px)' }),
          ...(children.props as React.HTMLAttributes<HTMLDivElement>)
        })}
        {endElement && (
          <InputElement placement="end" {...endElementProps}>
            {endElement}
          </InputElement>
        )}
      </Group>
    );
  }
);
