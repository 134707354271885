import { PieceOfInformationDto } from '@company/common/types';

export * from './file';
export * from './dto';

export const getPieceOfInformationItemId = (pieceOfInformation: PieceOfInformationDto) => {
  switch (pieceOfInformation.type) {
    case 'TABLE':
      return pieceOfInformation.table.id;
    case 'FILE':
      return pieceOfInformation.file.id;
    default:
      throw new Error('Invalid pieceOfInformation type');
  }
};
