import { z } from 'zod';
import { EnumMap } from '../utils';

export const platformLanguageTypeSchema = z.enum(['EN', 'DE']);
export type PlatformLanguageType = z.infer<typeof platformLanguageTypeSchema>;

export const PLATFORM_LANGUAGES: EnumMap<
  PlatformLanguageType,
  {
    localeCode: 'en' | 'de';
    locale: Intl.LocalesArgument;
  }
> = {
  EN: { id: 'EN', label: 'English', localeCode: 'en', locale: 'en-US' },
  DE: { id: 'DE', label: 'German', localeCode: 'de', locale: 'de-DE' }
};
