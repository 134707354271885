import { EnumMap } from '../utils';
import { z } from 'zod';

export const userRoleSchema = z.enum(['SUPER_ADMIN', 'ADMIN', 'MEMBER']);
export type UserRole = z.infer<typeof userRoleSchema>;

export const USER_ROLES: EnumMap<UserRole> = {
  SUPER_ADMIN: { id: 'SUPER_ADMIN', label: 'Super Admin' },
  ADMIN: { id: 'ADMIN', label: 'Admin' },
  MEMBER: { id: 'MEMBER', label: 'Member' }
};
