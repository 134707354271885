'use client';

import { Box, Center, Flex, Icon, Spacer, Spinner, Stack, Text } from '@chakra-ui/react';
import { CheckIcon, CircleXIcon, FileIcon, XIcon } from 'lucide-react';
import React, { ReactElement } from 'react';
import { IconButton } from '../button';
import { FileUploadStatus, UploadingFile } from './input/types';

export interface FileListProps {
  files: UploadingFile[];
  onRemoveFile: (fileId: string) => void;
  isReadOnly?: boolean;
}

export const FileList = ({ files, onRemoveFile, isReadOnly = false }: FileListProps) => {
  return (
    <Stack gap={0.5}>
      {files.map((file, index) => (
        <FileUploadItem
          key={index}
          file={file}
          removeFile={() => onRemoveFile(file.id)}
          isReadOnly={isReadOnly}
        />
      ))}
    </Stack>
  );
};

interface FileUploadItemProps {
  file: UploadingFile;
  removeFile: () => void;
  isReadOnly?: boolean;
}

const fileStatusToElement: Record<FileUploadStatus, ReactElement> = {
  UPLOADING: <Spinner size={'xs'} />,
  UPLOADED: <Icon as={CheckIcon} color={'green.500'} fontSize={18} />,
  ERROR: <Icon as={CircleXIcon} color={'red.500'} fontSize={18} />
};

const FileUploadItem: React.FC<FileUploadItemProps> = ({
  file,
  removeFile,
  isReadOnly = false
}) => {
  const fileStatusElement = React.useMemo(() => fileStatusToElement[file.status], [file.status]);
  return (
    <Box w={'full'}>
      <Flex
        align={'center'}
        pl={2}
        pr={3}
        py={0.5}
        borderWidth={1}
        borderColor={'gray.200'}
        borderRadius="md"
        h={'full'}
        w={'full'}
        bgColor={'white'}
      >
        <Center w={'20px'}>{isReadOnly ? <Icon as={FileIcon} /> : fileStatusElement}</Center>
        <Text fontSize={'sm'} pl={1} truncate maxW={'300px'}>
          {file.name}
        </Text>
        {!isReadOnly && (
          <>
            <Spacer />
            <IconButton aria-label="Remove file" size={'xs'} variant={'ghost'} onClick={removeFile}>
              <Icon as={XIcon} />
            </IconButton>
          </>
        )}
      </Flex>
    </Box>
  );
};
